import React, { useEffect, useState } from "react";
import { Grid, Button, Flex } from "basis";
import { useQueryParam, StringParam } from "use-query-params";
import { redirect } from "../../../core/navigate";

import {
  MERCHANT_PAGE_ROUTES,
  PAYMENT_METHOD,
  TRANSACTION_OUTCOME,
  TRANSACTION_TYPE,
} from "../../../core/constants";
import {
  LoadingMessage,
  Responsive,
  StyledHr,
  Text,
  Tabs,
  Column,
  ErrorMessage,
} from "../../../components";
import { substractAmount } from "../../../utils";
import { logger } from "../../../core";

import {
  StyledContainer,
  OrderMgtButtonHolder,
  OrderMgtModal,
  ItemDetails,
  OrderHistory,
  PaymentStatus,
  NavigationalLayout,
  SummaryOrderDetails,
  SummaryCustomerDetails,
  SummaryRewards,
  SummaryOrderManagement,
  SummaryPaymentPlan,
} from "../components";
import { transformOrder } from "../utils";
import * as api from "../utils/api";
import { BackIconLink } from "../components/BackIconLink";
import { useMerchantAuth } from "../../../core/auth";
import { setManualPayment } from "../../checkout/manualPayments";

const Scene = () => {
  const [orderData, setOrder] = useState([]);
  const [transactionId] = useQueryParam("transactionId", StringParam);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [actionType, setActionType] = useState();
  const [showAction, setShowAction] = useState(false);
  const { profile } = useMerchantAuth();
  useEffect(() => {
    const getTransactionDetails = async () => {
      if (!transactionId) {
        setError(new Error("invalid-request"));
        return;
      }
      try {
        const response = await api.getTransactionSummary(transactionId);

        if (!response) {
          setError(new Error("invalid-request"));
          redirect(MERCHANT_PAGE_ROUTES.Login);
          return;
        }

        setOrder(transformOrder(response));
        setLoading(false);
      } catch (err) {
        logger.error(err.message);
        setError(new Error("invalid-request"));
      }
    };

    getTransactionDetails();
  }, [transactionId]);

  const resendEncryptedLink = () => {
    const querystring = `?transactionId=${transactionId}`;
    setManualPayment(true);
    redirect(MERCHANT_PAGE_ROUTES.Summary, querystring);
  };

  const onManualPaymentClick = async (e) => {
    e.preventDefault();
    const { response, success } = await api.getManualPayment({
      transactionId,
    });
    if (success) {
      setManualPayment(true);
      window.location.replace(response);
    } else {
      setError(new Error("invalid-request"));
      return;
    }
  };

  const handleActionClick = ({ type }) => {
    setActionType(type);
    setShowAction(true);
    window.scrollTo(0, 0);
  };

  const handleActionExit = () => {
    setShowAction(false);
    window.location.reload(); // TODO: refresh the state instead of reloading the page
  };

  const refundableAmount = substractAmount(
    orderData?.type === TRANSACTION_TYPE.SALE
      ? orderData?.amount
      : orderData?.capturedAmount,
    orderData?.refundedAmount
  );

  if (error) {
    return (
      <NavigationalLayout>
        <ErrorMessage
          additionalParams={{
            urlCancelLabel: "Return to dashboard",
            urlCancel: MERCHANT_PAGE_ROUTES.OrderDashboard,
          }}
        />
      </NavigationalLayout>
    );
  }

  if (loading) {
    return (
      <NavigationalLayout>
        <LoadingMessage message="Please wait while we fetch the order details.." />
      </NavigationalLayout>
    );
  }

  return (
    <NavigationalLayout>
      <StyledContainer width="auto">
        <Column templateDesktop="2fr 3fr">
          <BackIconLink
            title="Back to Dashboard"
            onClick={() => redirect(MERCHANT_PAGE_ROUTES.OrderDashboard)}
          />

          <Responsive>
            <Text textStyle="heading4" margin="4 0">
              Order Summary
            </Text>

            <PaymentStatus status={orderData.friendlyStatus} />
          </Responsive>
        </Column>

        <StyledHr />

        {/* Order No, ref and buttons manual payment, resend payment link */}
        <Grid>
          <Grid.Item colSpan="all" colSpan-sm="0-5" colSpan-lg="0-5">
            <Text textStyle="subtitle2" margin="3 0 0">
            Purchase/Order Ref: <strong>{orderData.merchantReference}</strong>
            </Text>
            <Text textStyle="subtitle2">
              Latitude Transaction Ref:{" "}
              <strong>{orderData.transactionReference || "Pending"}</strong>
            </Text>
          </Grid.Item>

          <Grid.Item colSpan="all" colSpan-sm="6-12" colSpan-lg="6-12">
            {orderData?.friendlyStatus === TRANSACTION_OUTCOME.QUOTE &&
              Boolean(profile?.features?.isOrderCreateEnabled) && (
                <Flex placeItems="center right" margin="3 0">
                  <>
                    <Button
                      variant="secondary"
                      margin="0 2 0 0"
                      onClick={onManualPaymentClick}
                    >
                      Manual payment
                    </Button>
                    <Button
                      variant="secondary"
                      margin="0"
                      onClick={resendEncryptedLink}
                    >
                      Resend payment link
                    </Button>
                  </>
                </Flex>
              )}

            {!Boolean(profile?.features?.isOrderUpdateDisabled) && (
              <OrderMgtButtonHolder
                txnStatus={orderData?.friendlyStatus}
                orderData={orderData}
                onActionClick={handleActionClick}
              />
            )}
          </Grid.Item>
        </Grid>

        {/* Order Details ,  customer details, item details tab and order history tab */}
        <Grid preset="page" colsGap={0}>
          <Grid.Item
            colSpan="all"
            colSpan-sm="0-3"
            rowSpan-sm="5"
            colSpan-lg="0-5"
            rowSpan-lg="4"
          >
            <SummaryOrderDetails orderData={orderData} profile={profile} />
          </Grid.Item>
          <Grid.Item
            colSpan="all"
            colSpan-sm="5-8"
            rowSpan-sm="5"
            colSpan-lg="7-12"
            rowSpan-lg="4"
          >
            <SummaryCustomerDetails orderData={orderData} />
          </Grid.Item>
        </Grid>
        <Grid preset="page" colsGap={0}>
          <Grid.Item
            colSpan="all"
            colSpan-sm="0-3"
            rowSpan-sm="5"
            colSpan-lg="0-5"
            rowSpan-lg="4"
          >
            {profile?.paymentMethod === PAYMENT_METHOD.SALES_FINANCE_POINTS && (
              <SummaryRewards orderData={orderData} />
            )}
            <SummaryPaymentPlan orderData={orderData} />
          </Grid.Item>
          <Grid.Item
            colSpan="all"
            colSpan-sm="5-8"
            rowSpan-sm="5"
            colSpan-lg="7-12"
            rowSpan-lg="4"
          >
            <SummaryOrderManagement orderData={orderData} profile={profile} />
          </Grid.Item>

          {/* Item Details and Order History tabs */}
          <Grid.Item colSpan="all" rowSpan-sm="6" rowSpan-lg="5">
            <Tabs
              items={[
                {
                  id: "item-details",
                  title: "Item details",
                  ContentComponent: () => <ItemDetails orderData={orderData} />,
                },
                {
                  id: "order-history",
                  title: "Order history",
                  ContentComponent: () => (
                    <OrderHistory events={orderData?.events} />
                  ),
                },
              ]}
            />
          </Grid.Item>
        </Grid>
      </StyledContainer>

      {showAction && (
        <OrderMgtModal
          actionType={actionType}
          merchantId={orderData?.merchantId}
          transactionId={transactionId}
          merchantReference={orderData?.merchantReference}
          customerName={`${orderData?.customer?.firstName} ${orderData?.customer?.lastName}`}
          amount={orderData?.amount}
          currency={orderData?.currency}
          availableAmount={orderData?.availableAmount}
          refundableAmount={refundableAmount}
          onExit={() => handleActionExit()}
        />
      )}
    </NavigationalLayout>
  );
};

export default Scene;
